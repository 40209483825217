import React, { useState } from "react"
import styled, { css } from "styled-components"
import Layout from "../shared/Layout/layout"
import SEO from "../components/seo"
import { ImageBlock } from "../shared/Block/block"
import { ButtonLight } from "../shared/Button/button"

import WiresImage from "../images/wires.jpeg"

const InputDefaultStyle = css`
  display: flex;
  font-size: 1.15rem;
  font-family: sans-serif;

  line-height: 2.5rem;

  height: 2.5rem;

  outline: none;

  border: none;
  border-bottom: 2px solid transparent;
`

const Label = styled.div`
  ${InputDefaultStyle};

  width: 5rem;

  background-color: transparent;
  color: var(--color-primary-11);
`

const StyledTextInput = styled.input`
  ${InputDefaultStyle};

  padding: 0 0 0 1rem;
  width: 18rem;

  transition-property: background-color, color, box-shadow, resize;
  transition-duration: 0.2s, 0.2s, 0.3s, 0s;
  transition-timing-function: ease;

  background-color: var(--color-primary-05);
  color: var(--color-primary-12);

  &:not(:placeholder-shown):invalid:not(:focus):not(:hover) {
    box-shadow: 0px 0px 4px 2px var(--color-invalid);
  }

  &:invalid {
    color: var(--color-primary-12);
  }

  &:hover,
  &:active,
  &:focus {
    background-color: var(--color-primary-06);
    border-bottom: 2px solid transparent;
    color: var(--color-primary-12);
  }

  &:focus {
    background-color: transparent;
    background-color: var(--color-primary-05);
    box-shadow: 0px 0px 4px 2px var(--color-primary-02);
  }

  &::placeholder {
    color: var(--color-primary-08);
  }
`

const InputRow = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  background-color: transparent;

  flex-direction: row;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    & > ${StyledTextInput} {
      margin: 0 0 0.25rem 0;
    }
  }
`

const FormColumn = styled.div`
  flex-direction: column;
  align-items: flex-start;
  display: flex;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 68rem) {
    flex-direction: column;
  }

  & > ${FormColumn}:first-of-type {
    padding-right: 4rem;
  }
`

const P = styled.div`
  margin: 0 0 var(--default-margin) 0;
  padding: 0 0 0 0;

  font-size: 1.2rem;
  line-height: 2rem;
  font-family: nunito sans;

  text-shadow: 0px 0px 4px rgb(0, 0, 0);
`

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactPage = () => {
  const [formState, setFormState] = useState({
    fullname: "",
    email: "",
    phone: "",
    message: "",
  })

  const form_value = "Contact form"

  const handleSubmit = event => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": form_value, ...formState }),
    })
      .then(() =>
        alert(`Welcome ${JSON.stringify(formState)} ${formState.phone}!`)
      )
      .catch(error => alert(error))

    event.preventDefault()
  }

  const handleChange = event => {
    const { name, value } = event.target
    setFormState(formState => ({ ...formState, [name]: value }))
  }
  return (
    <Layout>
      <SEO title="Contact" />
      <ImageBlock
        image={WiresImage}
        cover_or_contain="cover"
        background_position="center"
        image_gradient="no-repeat radial-gradient(
        ellipse at top 38% left 45%,
        rgb(0, 0, 0, 0.8) 10%,
        transparent 100%
      )"
        background_position="left"
        min_height="60rem"
        min_mobile_height="85rem"
        preferred_width="45vw"
        preferred_max_width="68rem"
      >
        <div
          style={{
            display: `flex`,
            flexDirection: `column`,
            margin: `0 0 2rem 0`,
          }}
        >
          <h1 style={{ marginBottom: `6rem` }}>Ota yhteyttä</h1>
          <p>Lähetä viestiä, niin olen yhteydessä sinuun.</p>
          <P style={{ color: `var(--color-shock-3)` }}>
            Nykyisessä sivujen preview-versiossa tämä yhteydenottolomake on pois
            päältä. Sen sijaan ota yhteyttä suoraan sähköpostilla tai
            puhelimitse (tiedot löytyy sivun alalaidasta).
          </P>
        </div>

        <form
          name={form_value}
          // onSubmit={handleSubmit}
          data-netlify={true}
          netlify="true"
          action="/contacted"
          /*netlify-honeypot="bot-field"*/
        >
          <FormWrapper>
            <input type="hidden" name="form-name" value={form_value} />

            <FormColumn>
              <InputRow>
                <Label>Nimi</Label>
                <StyledTextInput
                  type="text"
                  placeholder="Matti Meikäläinen"
                  required
                  name="fullname"
                  onChange={handleChange}
                  value={formState.fullname}
                />
              </InputRow>

              <InputRow>
                <Label>Email</Label>
                <StyledTextInput
                  type="email"
                  placeholder="matti@meikalainen.com"
                  required
                  name="email"
                  onChange={handleChange}
                  value={formState.email}
                />
              </InputRow>

              <InputRow>
                <Label>Puhelin</Label>
                <StyledTextInput
                  type="tel"
                  placeholder="+358 50 123 4567"
                  required
                  name="phone"
                  onChange={handleChange}
                  value={formState.phone}
                />
              </InputRow>
            </FormColumn>

            <FormColumn>
              <InputRow>
                <Label>Viesti</Label>
                <StyledTextInput
                  as="textarea"
                  rows="9"
                  placeholder="Hei, Olisin kiinnostunut uusimaan sivujeni ulkoasun. Voitteko olla yhteydessä numerooni?"
                  style={{
                    resize: `none`,
                    overflow: `auto`,
                    flexGrow: `1`,
                    height: `auto`,
                    lineHeight: `1.5rem`,
                    padding: `0.5rem 1rem`,
                    width: `calc(18rem - 1rem)`,
                  }}
                  name="message"
                  onChange={handleChange}
                  value={formState.message}
                />
              </InputRow>
            </FormColumn>
          </FormWrapper>

          <ButtonLight
            as="input"
            type="submit"
            style={{ margin: `2rem 0 0 0` }}
            value="Lähetä"
          />

          <div data-netlify-recaptcha="true" />
        </form>
      </ImageBlock>
    </Layout>
  )
}

export default ContactPage
